import { Path } from "app/path";
import { SaleSliceState } from "app/redux/saleSlice";
import {
  AsButton,
  Header,
  LineItems,
  PersonInfo,
  ReceiptEnd,
  RequestedPayment,
  ServiceLineItem,
} from "app/subframe";
import { titleize } from "app/utils/string";
import { format } from "date-fns";
import { round } from "lodash";
import * as React from "react";
import {
  generatePath,
  redirect,
  useNavigate,
  useParams,
} from "react-router-dom";

export interface PayByCashProps {
  paymentLinkInfo: any;
  sale: SaleSliceState;
}

export const PayByCash: React.FC<PayByCashProps> = ({
  paymentLinkInfo,
  sale,
}) => {
  const navigate = useNavigate();
  const { token } = useParams();

  return (
    <div className="flex w-112 m-auto relative grow shrink-0 basis-0 flex-col items-center gap-6 rounded-lg bg-white px-2 py-4 mobile:w-full">
      <div className="w-full">
        <Header
          className="h-auto w-112 flex-none mobile:h-auto mobile:w-full mobile:flex-none"
          image={paymentLinkInfo.companyLogo}
          text={paymentLinkInfo.companyName}
        />
        {sale.accountDualPaymentEnabled && (
          <div className="w-full flex justify-start">
            <AsButton
              variant="link"
              text="Payment method"
              leadingIcon={"FeatherChevronLeft"}
              iconColor="text-neutral-700"
              className="text-neutral-700 font-400 pl-0"
              onClick={() =>
                navigate(generatePath(Path.PAYMENT, { token: token! }))
              }
            />
          </div>
        )}
      </div>
      <RequestedPayment text={"Pay by cash"} single />
      <div className="flex w-112 grow shrink-0 basis-0 flex-col items-center gap-6 px-2 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:gap-6 mobile:px-2 mobile:py-0">
        <div className="flex w-full border border-gray-200 rounded p-3 bg-gray-50 flex-col items-center gap-2">
          <span className="w-full font-['Inter'] text-[18px] font-[500] leading-[28px] text-neutral-900">
            {sale.shop.name}
          </span>
          <div className="flex w-full flex-col items-center gap-1">
            <span className="w-full font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-500">
              {sale.shop.addressLine1}
            </span>
            <span className="w-full font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-500">
              {sale.shop.addressLine2}
            </span>
          </div>
        </div>
        <PersonInfo
          className="h-auto w-full flex-none mobile:h-auto mobile:w-full mobile:flex-none"
          name={titleize(sale.client.name)}
          subtitle={`Serviced on ${
            sale?.appointmentGroup?.appointments.length > 0
              ? format(
                  new Date(sale?.appointmentGroup?.appointments[0]?.endTime),
                  "M.dd.yyyy"
                )
              : format(new Date(), "M.dd.yyyy")
          }`}
        />

        {[...sale.unpaidMerchantServices, ...sale.paidMerchantServices].map(
          (service: any, index: any) => (
            <ServiceLineItem
              key={index}
              serviceName={service.title}
              conjunction="with"
              staff={service.employee.name}
              price={service.price}
            />
          )
        )}
        <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-100" />
        <LineItems title="Subtotal" price={sale.subtotal} />
        <LineItems title="Cash Discount" price={`-${sale.cashDiscount}`} />
        <LineItems title="Tax" price={sale.tax} />
        <LineItems title="Tip" price={sale.presaleTips} />
        <LineItems
          title="Total"
          price={`$${round(
            (sale?.totalCents + sale?.presaleTipsCents) / 100,
            2
          ).toFixed(2)}`}
        />
      </div>
      <ReceiptEnd
        className="w-full"
        image="https://res.cloudinary.com/subframe/image/upload/v1726699577/uploads/3988/fw4c3atrh8tmapk0hk7t.svg"
      />
    </div>
  );
};
