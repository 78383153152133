import * as React from "react";
import {
  DualPricingPaymentTypes,
  PaymentStepType,
  usePayment,
} from "./usePayment";
import { Header } from "app/subframe/components/Header";
import { RequestedPayment } from "app/subframe/components/RequestedPayment";
import { PersonInfo } from "app/subframe/components/PersonInfo";
import { ServiceLineItem } from "app/subframe/components/ServiceLineItem";
import { LineItems } from "app/subframe/components/LineItems";
import { CtaPopUp } from "app/subframe/components/CtaPopUp";
import { titleize } from "app/utils/string";
import { format } from "date-fns";
import { AsButton, PoweredBy, ReceiptEnd } from "app/subframe";
import { InitialModal } from "./components/modal/initialModal/initialModal";
import { TipsModal } from "./components/modal/tipsModal/tipsModal";
import classNames from "classnames";
import { PayWith } from "./components/modal/payWith/payWith";
import { PayWithCc } from "./components/modal/payWithCc/payWithCc";
import { Loading } from "app/components/loading/loading";
import { motion } from "framer-motion"; // Import framer-motion
import { PaymentFailed } from "./components/modal/paymentFailed/paymentFailed";
import { Icon } from "@subframe/core";
import { ChoosePaymentMethod } from "./components/choosePaymentMethod/choosePaymentMethod";

export interface PaymentProps {}

export const Payment: React.FC<PaymentProps> = ({}) => {
  const {
    sale,
    amountDueCents,
    paymentLinkInfo,
    paymentStep,
    setPaymentStep,
    tipAmountCents,
    onTipOptionSelect,
    onCustomTipOptionChange,
    selectedTipOption,
    customTip,
    isProcessingPayment,
    setIsProcessingPayment,
    wholeSaleTotalCents,
    shouldAnimate,
    isPaymentLinkValid,
    loading,
    showPaymentMethods,
    setShowPaymentMethods,
    getSubtotal,
    token,
    hasUnpaidMerchantServicesWithDualPricing,
  } = usePayment();

  if (loading) {
    return <Loading fullScreen showBackdrop />;
  }

  if (!isPaymentLinkValid) {
    return (
      <div className="flex w-full max-w-96 absolute top-1/3 left-1/2 -translate-x-1/2 flex-col items-center gap-4 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4">
        <div className="flex w-full items-center gap-2">
          <Icon
            className="text-body font-body text-error-500"
            name="FeatherAlertTriangle"
          />
          <span className="text-[14px] font-[600] leading-[20px] text-neutral-900">
            Invalid Payment link, please contact {paymentLinkInfo.companyName}.
          </span>
        </div>
        {paymentLinkInfo.companyEmail && (
          <AsButton
            className="h-14 w-full flex-none"
            variant="secondary"
            trailingIcon={null}
            text="Email"
            size="lg"
            onClick={() => {
              window.location.href = `mailto:${paymentLinkInfo.companyEmail}`;
            }}
          />
        )}
      </div>
    );
  }

  const getModalType = () => {
    switch (paymentStep) {
      case PaymentStepType.INITIAL:
        return <InitialModal setPaymentStep={setPaymentStep} />;
      case PaymentStepType.TIPS:
        return (
          <TipsModal
            setPaymentStep={setPaymentStep}
            onTipOptionSelect={onTipOptionSelect}
            onCustomTipOptionChange={onCustomTipOptionChange}
            selectedTipOption={selectedTipOption}
            customTip={customTip}
          />
        );
      case PaymentStepType.PAY_WITH:
        return (
          <PayWith
            amountDueCents={amountDueCents}
            setPaymentStep={setPaymentStep}
          />
        );
      case PaymentStepType.PAY_WITH_CC:
        return (
          <PayWithCc
            setPaymentStep={setPaymentStep}
            paymentLinkInfo={paymentLinkInfo}
            sale={sale}
            amountDueCents={amountDueCents}
            tipAmountCents={tipAmountCents}
            setIsProcessingPayment={setIsProcessingPayment}
          />
        );
      case PaymentStepType.PAYMENT_FAILED:
        return (
          <div className="h-screen justify-center bg-white flex items-center">
            <PaymentFailed setPaymentStep={setPaymentStep} />
          </div>
        );
      default:
        return <CtaPopUp />;
    }
  };

  return (
    <div className="relative container py-4 h-screen max-w-none flex w-full flex-col items-center gap-4 mobile:flex-col mobile:items-center mobile:justify-start mobile:gap-2 mobile:bg-millet-50 mobile:px-0 mobile:py-0">
      {showPaymentMethods ? (
        <ChoosePaymentMethod
          paymentLinkInfo={paymentLinkInfo}
          setShowPaymentMethods={setShowPaymentMethods}
          cardSubtotal={getSubtotal({ method: DualPricingPaymentTypes.CARD })}
          cashSubtotal={getSubtotal({ method: DualPricingPaymentTypes.CASH })}
          token={token!}
          sale={sale}
          hasPaidAndUnpaidMerchantServicesWithDualPricing={
            hasUnpaidMerchantServicesWithDualPricing &&
            sale.paidMerchantServices.length > 0
          }
        />
      ) : (
        <>
          <div
            className={classNames(
              "absolute z-10 top-0 left-0 w-full bg-[#171718]/80 h-full",
              {
                hidden:
                  paymentStep === PaymentStepType.INITIAL ||
                  !paymentStep ||
                  paymentStep === PaymentStepType.PAYMENT_FAILED,
                visible:
                  paymentStep !== PaymentStepType.INITIAL &&
                  !isProcessingPayment &&
                  paymentStep !== PaymentStepType.PAYMENT_FAILED,
              }
            )}
            onClick={() => setPaymentStep(PaymentStepType.INITIAL)}
          />
          <div className="w-112 mobile:w-full">
            <Header
              className="h-auto w-112 flex-none mobile:h-auto mobile:w-full mobile:flex-none"
              image={paymentLinkInfo.companyLogo}
              text={paymentLinkInfo.companyName}
            />
            {hasUnpaidMerchantServicesWithDualPricing && (
              <div className="w-full flex justify-start">
                <AsButton
                  variant="link"
                  text="Payment method"
                  leadingIcon={"FeatherChevronLeft"}
                  iconColor="text-neutral-700"
                  className="text-neutral-700 font-400 pl-0"
                  onClick={() => setShowPaymentMethods(true)}
                />
              </div>
            )}
          </div>
          <div
            className={classNames(
              "flex w-112 grow shrink-0 basis-0 flex-col items-center gap-6 px-2 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:gap-6",
              {
                "pb-[5dvh]":
                  paymentStep === PaymentStepType.INITIAL ||
                  isProcessingPayment,
                // "pb-[20dvh] -translate-y-32":
                //   paymentStep !== PaymentStepType.INITIAL && !isProcessingPayment,
              }
            )}
          >
            <RequestedPayment
              text={
                sale.paidBalanceCents === sale.totalCents &&
                sale.paidTipsCents === 0
                  ? "Requesting tips"
                  : "Requested payment"
              }
              text2={`$${(amountDueCents / 100).toFixed(2)}`}
              single={
                sale.paidBalanceCents === sale.totalCents &&
                sale.paidTipsCents === 0
              }
            />
            <PersonInfo
              className="h-auto w-full flex-none mobile:h-auto mobile:w-full mobile:flex-none"
              name={titleize(sale.client.name)}
              subtitle={`Serviced on ${
                sale?.appointmentGroup?.appointments.length > 0
                  ? format(
                      new Date(
                        sale?.appointmentGroup?.appointments[0]?.endTime
                      ),
                      "M.dd.yyyy"
                    )
                  : format(new Date(), "M.dd.yyyy")
              }`}
            />
            <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-100 mobile:items-center mobile:justify-center mobile:bg-neutral-100" />
            <div className="flex flex-col gap-8 w-full">
              {sale.paidMerchantServices.map((service, index) => (
                <ServiceLineItem
                  key={index}
                  serviceName={service.title}
                  conjunction="with"
                  staff={service.employee.name}
                  price={service.price}
                  paidStatus={
                    hasUnpaidMerchantServicesWithDualPricing
                      ? "default"
                      : "paid"
                  }
                />
              ))}
              {sale.unpaidMerchantServices.map((service, index) => (
                <ServiceLineItem
                  key={index}
                  serviceName={service.title}
                  conjunction="with"
                  staff={service.employee.name}
                  price={service.price}
                  paidStatus={
                    hasUnpaidMerchantServicesWithDualPricing
                      ? "default"
                      : sale.paidMerchantServices.length > 0
                      ? "unpaid"
                      : "default"
                  }
                />
              ))}
            </div>
            <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-100" />
            <LineItems title="Subtotal" price={sale.subtotal} />
            {sale.paidTaxCents > 0 &&
            !hasUnpaidMerchantServicesWithDualPricing ? (
              <>
                <LineItems title="Already taxed" price={sale.paidTax} />
                <LineItems
                  title="Tax (on unpaid line items)"
                  price={`$${(
                    (sale.taxCents - sale.paidTaxCents) /
                    100
                  ).toFixed(2)}`}
                />
              </>
            ) : (
              <LineItems title="Tax" price={sale.tax} />
            )}

            {sale.paidTipsCents > 0 ? (
              <>
                <LineItems title="Already tipped" price={sale.paidTips} />
                <LineItems
                  title="Tip on unpaid"
                  price={`$${(tipAmountCents / 100).toFixed(2)}`}
                />
              </>
            ) : (
              <LineItems
                title="Tip"
                price={`$${(tipAmountCents / 100).toFixed(2)}`}
              />
            )}
            <LineItems
              title="Total"
              price={`$${(wholeSaleTotalCents / 100).toFixed(2)}`}
            />

            {sale.paidBalanceCents > 0 &&
              !hasUnpaidMerchantServicesWithDualPricing && (
                <>
                  <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-100" />
                  <LineItems title="Already paid" price={sale.paidBalance} />
                  <LineItems
                    title="Remaining balance"
                    price={`$${(amountDueCents / 100).toFixed(2)}`}
                  />
                </>
              )}
          </div>
          <ReceiptEnd
            className="pb-16"
            image="https://res.cloudinary.com/subframe/image/upload/v1726699577/uploads/3988/fw4c3atrh8tmapk0hk7t.svg"
          />
          {isProcessingPayment && (
            <div className="fixed top-1/3 left-1/2 -translate-x-1/2 z-20">
              <Loading
                label="Processing payment"
                labelClassName="text-white"
                containerClassName="w-full z-20"
              />
            </div>
          )}
          <motion.div
            key={paymentStep} // Use paymentStep as a key to trigger re-mount
            initial={shouldAnimate ? { y: 200, opacity: 0 } : {}} // Start off-screen if animating
            animate={shouldAnimate ? { y: 0, opacity: 1 } : {}} // Slide up and fade in if animating
            transition={{ duration: 0.5, ease: "easeInOut" }} // Animation settings
            className={classNames(
              "fixed bottom-0 z-20 w-112 shadow-pop-up-shadow flex-none mobile:w-full mobile:flex-none flex flex-col items-center gap-2 rounded-t-lg px-2 py-4",
              {
                "opacity-0": isProcessingPayment,
                "translate-y-1":
                  paymentStep !== PaymentStepType.INITIAL &&
                  paymentStep !== PaymentStepType.PAYMENT_FAILED &&
                  !isProcessingPayment, // Slide out of view
                "backdrop-blur-sm bg-[#ffffff80] h-30":
                  paymentStep === PaymentStepType.INITIAL,
                "bg-white h-96":
                  paymentStep !== PaymentStepType.INITIAL &&
                  paymentStep !== PaymentStepType.PAYMENT_FAILED &&
                  !isProcessingPayment,
              }
            )}
          >
            <div className="flex flex-col gap-2 w-full">
              {getModalType()}
              <PoweredBy
                className={classNames({
                  hidden: paymentStep !== PaymentStepType.INITIAL,
                })}
                text="Powered by"
              />
            </div>
          </motion.div>
        </>
      )}
    </div>
  );
};
