import { Path } from "app/path";
import { getPaymentLink } from "app/redux/paymentLinkSlice";
import { SaleSliceState, getSale } from "app/redux/saleSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export enum DualPricingPaymentTypes {
  CARD = "card",
  CASH = "cash",
}

export const useDualPricing = () => {
  const { method, token } = useParams();
  const dispatch = useDispatch();
  const [paymentLinkInfo, setPaymentLinkInfo] = useState<any>(null);
  const [sale, setSale] = useState<SaleSliceState>({} as SaleSliceState);
  const [loading, setLoading] = useState<boolean>(true);

  const init = async () => {
    try {
      setLoading(true);
      const fetchedPaymentLinkInfo = await dispatch(
        getPaymentLink(token!) as any
      ).unwrap();

      setPaymentLinkInfo(fetchedPaymentLinkInfo);

      const fetchedSale = await dispatch(
        getSale({
          token: fetchedPaymentLinkInfo.token,
          asCashDualPricing: method === DualPricingPaymentTypes.CASH,
        }) as any
      ).unwrap();
      setSale(fetchedSale);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return {
    method,
    paymentLinkInfo,
    loading,
    sale,
  };
};
