import { Path } from "app/path";
import { getPaymentLink, selectPaymentLink } from "app/redux/paymentLinkSlice";
import {
  getSale,
  SaleSliceState,
  selectSale,
  sendPostPaymentReceiptEmail,
} from "app/redux/saleSlice";
import { includes } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export const usePaymentSuccess = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const navigate = useNavigate();
  const reduxSale = useSelector(selectSale);
  const reduxPaymentLink = useSelector(selectPaymentLink);
  const [paymentLinkInfo, setPaymentLinkInfo] = useState<any>(reduxPaymentLink);
  const [sale, setSale] = useState<SaleSliceState>(reduxSale as SaleSliceState);
  const [emailToSendReceipt, setEmailToSendReceipt] = useState<string>(
    sale?.client?.email
  );
  const [loading, setLoading] = useState<boolean>(true);
  const invalidStatuses = ["pending", "expired"];

  const init = async () => {
    setLoading(true);
    if (!token) {
      toast.error("Invalid payment link");
      return navigate(Path.HOME);
    }

    if (includes(invalidStatuses, reduxPaymentLink?.status)) {
      navigate(generatePath(Path.PAYMENT, { token }));
      return setLoading(false);
    }

    if (reduxSale.id && reduxPaymentLink.id) {
      return setLoading(false);
    }
    try {
      const fetchedPaymentLinkInfo = await dispatch(
        getPaymentLink(token) as any
      ).unwrap();
      if (includes(invalidStatuses, fetchedPaymentLinkInfo.status)) {
        navigate(generatePath(Path.PAYMENT, { token }));
        return setLoading(false);
      }
      setPaymentLinkInfo(fetchedPaymentLinkInfo);
      const fetchedSale = await dispatch(
        getSale({ token: fetchedPaymentLinkInfo.token }) as any
      ).unwrap();
      setSale(fetchedSale);
      setEmailToSendReceipt(fetchedSale.client.email);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const onSendReceiptWithEmail = async (event: any) => {
    event.preventDefault();
    try {
      await dispatch(
        sendPostPaymentReceiptEmail({
          email: emailToSendReceipt,
          token: paymentLinkInfo.token,
        }) as any
      ).unwrap();
      toast.success(`Receipt has been sent to ${emailToSendReceipt}`);
    } catch (error) {
      console.log("Email me this receipt with email", emailToSendReceipt);
    }
  };

  useEffect(() => {
    init();
    if (window.scrollY !== 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  return {
    sale,
    paymentLinkInfo,
    emailToSendReceipt,
    setEmailToSendReceipt,
    onSendReceiptWithEmail,
    loading,
  };
};
