import { Path } from "app/path";
import { getPaymentLink } from "app/redux/paymentLinkSlice";
import { getSale, SaleSliceState } from "app/redux/saleSlice";
import { getFormValues } from "app/utils/getFormValues";
import { round, set, sumBy } from "lodash";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export enum PaymentStepType {
  INITIAL = "initial",
  TIPS = "tips",
  PAY_WITH = "pay_with",
  PAY_WITH_CC = "pay_with_cc",
  PAYMENT_FAILED = "payment_failed",
}

export enum DualPricingPaymentTypes {
  CARD = "card",
  CASH = "cash",
}

export const usePayment = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const navigate = useNavigate();
  const [paymentStep, setPaymentStep] = useState<PaymentStepType>(
    PaymentStepType.INITIAL
  );
  const [paymentLinkInfo, setPaymentLinkInfo] = useState<any>(null);
  const [sale, setSale] = useState<SaleSliceState>({} as SaleSliceState);
  const [
    currentTransactionOriginalTotalCents,
    setCurrentTransactionOriginalTotalCents,
  ] = useState<number>(sale.totalCents - sale.paidBalanceCents);
  const [wholeSaleTotalCents, setWholeSaleTotalCents] = useState<number>(0);
  const [amountDueCents, setAmountDueCents] = useState<number>(0);
  const [tipAmountCents, setTipAmountCents] = useState<number>(0);
  const [selectedTipOption, setSelectedTipOption] = useState<number | null>(
    null
  );
  const [customTip, setCustomTip] = useState<number | null>(null);
  const [isProcessingPayment, setIsProcessingPayment] =
    useState<boolean>(false);
  const prevPaymentStepRef = useRef(paymentStep); // Track previous payment step
  const [isPaymentLinkValid, setIsPaymentLinkValid] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [showPaymentMethods, setShowPaymentMethods] = useState<boolean>(false);
  const [
    hasUnpaidMerchantServicesWithDualPricing,
    setHasUnpaidMerchantServicesWithDualPricing,
  ] = useState<boolean>(false);

  const onTipOptionSelect = (tipPercent: number) => {
    setCustomTip(0);
    setSelectedTipOption(tipPercent);
    let amountToTipFrom;

    if (sale.paidTipsCents === 0) {
      amountToTipFrom = sale.subtotalCents;
    } else {
      amountToTipFrom = currentTransactionOriginalTotalCents;
    }

    setTipAmountCents(amountToTipFrom * (tipPercent / 100));
  };

  const getSubtotal = ({
    method = DualPricingPaymentTypes.CARD,
  }: {
    method: DualPricingPaymentTypes;
  }) => {
    const cardSubtotal = round(sale.subtotalCents / 100, 2);

    if (method === DualPricingPaymentTypes.CARD) {
      return `$${round(cardSubtotal, 2).toFixed(2)}`;
    } else {
      const cashSubtotal = cardSubtotal * 0.96;
      return `$${round(cashSubtotal, 2).toFixed(2)}`;
    }
  };

  const onCustomTipOptionChange = (e: any) => {
    const customTipAmount = e.target.value;
    setCustomTip(customTipAmount);
    setSelectedTipOption(null);
    setTipAmountCents(customTipAmount * 100);
  };

  useEffect(() => {
    const newTipCents = Number(tipAmountCents.toFixed(2));
    setAmountDueCents(currentTransactionOriginalTotalCents + newTipCents);
    setWholeSaleTotalCents(sale.totalCents + newTipCents);
  }, [tipAmountCents, currentTransactionOriginalTotalCents]);

  const init = async () => {
    if (!token) {
      toast.error("Invalid payment link");
      setIsPaymentLinkValid(false);
      setLoading(false);
      return;
    }

    if (paymentLinkInfo?.status === "expired") {
      setIsPaymentLinkValid(false);
      setLoading(false);
      return;
    }
    try {
      const fetchedPaymentLinkInfo = await dispatch(
        getPaymentLink(token) as any
      ).unwrap();
      if (fetchedPaymentLinkInfo.status === "paid") {
        return navigate(generatePath(Path.PAYMENT_SUCCESS, { token }));
      }
      setPaymentLinkInfo(fetchedPaymentLinkInfo);
      if (fetchedPaymentLinkInfo.status === "expired") {
        setIsPaymentLinkValid(false);
        setLoading(false);
        return;
      }
      const fetchedSale = await dispatch(
        getSale({ token: fetchedPaymentLinkInfo.token }) as any
      ).unwrap();
      setSale(fetchedSale);

      const fetchedSaleHasUnpaidMerchantServicesWithDualPricing =
        fetchedSale.accountDualPaymentEnabled &&
        fetchedSale.unpaidMerchantServices.length > 0;

      setHasUnpaidMerchantServicesWithDualPricing(
        fetchedSaleHasUnpaidMerchantServicesWithDualPricing
      );

      setCurrentTransactionOriginalTotalCents(
        fetchedSaleHasUnpaidMerchantServicesWithDualPricing
          ? fetchedSale.totalCents
          : fetchedSale.totalCents - fetchedSale.paidBalanceCents
      );

      setAmountDueCents(
        fetchedSaleHasUnpaidMerchantServicesWithDualPricing
          ? fetchedSale.totalCents + fetchedSale.presaleTipsCents
          : fetchedSale.totalCents -
              fetchedSale.paidBalanceCents +
              (fetchedSale.presaleTipsCents - fetchedSale.paidTipsCents)
      );
      setWholeSaleTotalCents(fetchedSale.totalCents);
      initTips(fetchedSale);

      setShowPaymentMethods(
        fetchedSaleHasUnpaidMerchantServicesWithDualPricing
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const initTips = (sale: any) => {
    const presaleTipPercentage =
      (sale.presaleTipsCents / sale.subtotalCents) * 100;

    if (
      presaleTipPercentage % 18 === 0 ||
      presaleTipPercentage % 20 === 0 ||
      presaleTipPercentage % 22 === 0
    ) {
      setSelectedTipOption(presaleTipPercentage);
    } else {
      setSelectedTipOption(null);
      setCustomTip(sale.presaleTipsCents);
    }
    setTipAmountCents(sale.presaleTipsCents);
  };

  const shouldAnimate = React.useMemo(() => {
    // Check if we're transitioning from INITIAL to any other step
    return (
      prevPaymentStepRef.current === PaymentStepType.INITIAL &&
      paymentStep !== PaymentStepType.INITIAL
    );
  }, [paymentStep]);

  // Update the previous payment step reference on render
  React.useEffect(() => {
    prevPaymentStepRef.current = paymentStep;
  }, [paymentStep]);

  useEffect(() => {
    init();
  }, []);

  return {
    sale,
    paymentLinkInfo,
    amountDueCents,
    paymentStep,
    setPaymentStep,
    tipAmountCents,
    setTipAmountCents,
    setAmountDueCents,
    currentTransactionOriginalTotalCents,
    onTipOptionSelect,
    onCustomTipOptionChange,
    selectedTipOption,
    customTip,
    isProcessingPayment,
    setIsProcessingPayment,
    wholeSaleTotalCents,
    shouldAnimate,
    isPaymentLinkValid,
    loading,
    showPaymentMethods,
    setShowPaymentMethods,
    getSubtotal,
    token,
    hasUnpaidMerchantServicesWithDualPricing,
  };
};
